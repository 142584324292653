import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/privacy.scss";

class Privacy extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="privacy-page">
          <div className="title">
            Privacy Policy
            <div className="bgc"></div>
          </div>
          <p>
            <b>Owner and Data Controller</b>
          </p>
          <p>P.O Box 1691 Pawcatuck CT 06379</p>
          <p>Owner contact email: support@flocksy.com</p>
          <p>
            <b>Types of Data collected</b>
          </p>
          <p>
            Among the types of Personal Data that this Application collects, by
            itself or through third parties, there are: Cookies; Usage Data;
            unique device identifiers for advertising (Google Advertiser ID or
            IDFA, for example); email address; first name; last name; phone
            number; Data communicated while using the service.
          </p>
          <p>
            Complete details on each type of Personal Data collected are
            provided in the dedicated sections of this privacy policy or by
            specific explanation texts displayed prior to the Data collection.
          </p>
          <p>
            Personal Data may be freely provided by the User, or, in case of
            Usage Data, collected automatically when using this Application.
            Unless specified otherwise, all Data requested by this Application
            is mandatory and failure to provide this Data may make it impossible
            for this Application to provide its services. In cases where this
            Application specifically states that some Data is not mandatory,
            Users are free not to communicate this Data without consequences to
            the availability or the functioning of the Service.
          </p>
          <p>
            Users who are uncertain about which Personal Data is mandatory are
            welcome to contact the Owner. Any use of Cookies – or of other
            tracking tools — by this Application or by the owners of third-party
            services used by this Application serves the purpose of providing
            the Service required by the User, in addition to any other purposes
            described in the present document and in the Cookie Policy.
          </p>
          <p>
            Users are responsible for any third-party Personal Data obtained,
            published or shared through this Application.
          </p>
          <p>
            <b>Mode and place of processing the Data</b>
          </p>
          <p>
            <b>Methods of processing</b>
          </p>
          <p>
            The Owner takes appropriate security measures to prevent
            unauthorized access, disclosure, modification, or unauthorized
            destruction of the Data. The Data processing is carried out using
            computers and/or IT enabled tools, following organizational
            procedures and modes strictly related to the purposes indicated. In
            addition to the Owner, in some cases, the Data may be accessible to
            certain types of persons in charge, involved with the operation of
            this Application (administration, sales, marketing, legal, system
            administration) or external parties (such as third-party technical
            service providers, mail carriers, hosting providers, IT companies,
            communications agencies) appointed, if necessary, as Data Processors
            by the Owner. The updated list of these parties may be requested
            from the Owner at any time.
          </p>
          <p>
            <b>Place</b>
          </p>
          <p>
            The Data is processed at the Owner's operating offices and in any
            other places where the parties involved in the processing are
            located.
          </p>
          <p>
            Depending on the User's location, data transfers may involve
            transferring the User's Data to a country other than their own. To
            find out more about the place of processing of such transferred
            Data, Users can check the section containing details about the
            processing of Personal Data.
          </p>
          <p>
            <b>Retention time</b>
          </p>
          <p>
            Unless specified otherwise in this document, Personal Data shall be
            processed and stored for as long as required by the purpose they
            have been collected for and may be retained for longer due to
            applicable legal obligation or based on the Users’ consent.
          </p>
          <p>
            <b>The purposes of processing</b>
          </p>
          <p>
            The Data concerning the User is collected to allow the Owner to
            provide its Service, comply with its legal obligations, respond to
            enforcement requests, protect its rights and interests (or those of
            its Users or third parties), detect any malicious or fraudulent
            activity, as well as the following: Analytics, Interaction with
            external social networks and platforms, Displaying content from
            external platforms, Heat mapping and session recording, Handling
            payments, Advertising, Contacting the User, Remarketing and
            behavioral targeting, Backup saving and management, Hosting and
            backend infrastructure and Tag Management.
          </p>
          <p>
            For specific information about the Personal Data used for each
            purpose, the User may refer to the section “Detailed information on
            the processing of Personal Data”.
          </p>
          <p>
            <b>Detailed information on the processing of Personal Data</b>
          </p>
          <p>
            Personal Data is collected for the following purposes and using the
            following services:
          </p>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Privacy;
