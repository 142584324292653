import React from "react";

const Instagram = ({
    viewBox = '0 0 24 24',
    size = 24,
    color="#267ca8",
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="18629"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M944.5888 0H76.5952C34.048 0 0 34.1504 0 73.9328v876.1344C0 989.8496 34.048 1024 76.5952 1024h870.8096c42.5472 0 76.5952-34.1504 76.5952-73.9328V73.9328A80.384 80.384 0 0 0 944.5888 0zM303.5136 873.2672H150.3232V384h150.3744v489.2672h2.816zM226.9184 315.6992a88.4736 88.4736 0 0 1-87.9104-88.1664c0-48.3328 39.68-85.2992 87.9104-85.2992 48.2304 0 87.9616 39.8336 87.9616 88.1664s-39.7312 85.3504-87.9616 85.3504z m643.8912 557.568h-150.3232v-238.9504c0-56.8832 0-130.8672-79.4112-130.8672-79.4624 0-90.7776 62.6176-90.7776 125.184V870.4H397.1072V384h144.6912v65.4336h2.816c19.8656-39.8336 70.912-79.6672 144.6912-79.6672 153.1392 0 181.504 102.4 181.504 233.2672v270.2336z"
      fill={color}
      p-id="18630"
    ></path>
  </svg>
);


export default Instagram;