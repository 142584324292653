import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/contact.scss";

class Contact extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="contact-page">
          <div className="title">
            Contact Us
            <div className="bgc"></div>
          </div>
          <p>
            Take advantage of our friendly and responsive customer service for
            any questions.
          </p>
          {/* email */}
          <p>Talk to a real live human at shop@vfios.store</p>
          <p>Response time is usually within an hour during business hours.</p>
          <div className="row">
            <div className="title"> Subject*</div>
            <input type="text" />
          </div>
          <div className="row">
            <div className="title"> Email*</div>
            <input type="text" />
          </div>
          <div className="row">
            <div className="title"> Message*</div>
            <textarea></textarea>
          </div>
          <div className="row">
            <div className="btn">Submit message</div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Contact;
