import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/pricing.scss";
import sec1 from "../image/Pricing-MainBanner-Img1.pngw3.webp";
import sec2 from "../image/14DaysMoneyNewImg1-SVG.svg";
import priceSave from "../image/Pricing-Save-Orange-BG-SVG.svg";
import TipIcon from "../icon/TipIcon";
import ErrorIcon from "../icon/ErrorIcon";
import SuccessIcon from "../icon/SuccessIcon";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  routePage(type) {
    let { history } = this.props;
    history.push(`/Checkout/${type}`);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="price-page">
          <div className="sec1">
            <div className="left">
              <div className="title">
                Unlimited Projects for
                <br /> <span>One Flat Rate</span>
                <div className="bgc"></div>
              </div>
              <p>
                Get unlimited graphic design, video editing,
                <br /> copywriting, virtual assistants, motion graphics,
                <br /> illustration, voice overs and web design for one <br />
                affordable, flat-rate monthly payment.
              </p>
            </div>
            <div className="right">
              <img src={sec1} alt="" />
            </div>
          </div>
          <div className="sec2">
            <div className="img">
              <img src={sec2} alt="" />
            </div>
            <div className="right">
              <div className="title">
                Choose <br /> <span>Your Plan</span>
                <div className="bgc"></div>
              </div>
              <p>
                All three plans come with our hassle free{" "}
                <b>14-Day Money Back Guarantee</b>.
              </p>
            </div>
          </div>
          <div className="sec3">
            <div className="left">
              <div className="title-bar">Monthly</div>
              <div className="col">
                <div className="top-title">BASIC</div>
                <div className="price-title">
                  Sliver Plan <TipIcon size={12} color="#16cc8e" />
                </div>
                <div className="price-value">$499</div>
                <div className="bottom-title">/month</div>
                <div className="service">Services</div>
                <ul className="service-ul">
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Graphic Design</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Custom Illustrations</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Logos and Branding Guides</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Presentation Design</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Video Editing</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Motion Graphics</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Copywriting</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Virtual Assistants</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">PSD to Drag and Drop Builders</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">PSD to HTML</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <div className="feature">features</div>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Automated Project Queue</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>3 Users</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>5 Brand Buckets </p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Zapier Integration</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Canva Delivery</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>24-48 Hours Turnaround</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">All Assist</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Dedicated Project Manager</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Real-Time Collaboration</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Double the Output</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Make Requests via Video & Audio</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Figma Delivery</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <div
                    className="btn"
                    onClick={() => this.routePage("Sliver")}
                  >
                    Next
                  </div>
                </ul>
              </div>
            </div>
            <div className="middle">
              <div className="title-bar">
                Quarterly
                <div className="save">
                  <img src={priceSave} alt="" />
                </div>
                <div className="save-p">
                  save <br /> 10%
                </div>
              </div>
              <div className="col">
                <div className="top-title">BEST VALUE</div>
                <div className="price-title">
                  Gold Plan <TipIcon size={12} color="#16cc8e" />
                </div>
                <div className="price-value">$995</div>
                <div className="bottom-title">/month</div>
                <div className="service">Services</div>
                <ul className="service-ul">
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Graphic Design</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Custom Illustrations</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Logos and Branding Guides</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Presentation Design</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Video Editing</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Motion Graphics</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Copywriting</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Virtual Assistants</p>
                    <TipIcon size={12} />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">PSD to Drag and Drop Builders</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">PSD to HTML</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <div className="feature">features</div>{" "}
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>{" "}
                    <p>Automated Project Queue</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>{" "}
                    <p>10 Users</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>{" "}
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>15 Brand Buckets </p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Zapier Integration</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Canva Delivery</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Expedited Turnaround</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>All Assist</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Dedicated Project Manager</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Real-Time Collaboration</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Double the Output</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Make Requests via Video & Audio</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <ErrorIcon size={20} />
                    </div>
                    <p className="err">Figma Delivery</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <div className="btn" onClick={() => this.routePage("Gold")}>
                    Next
                  </div>
                </ul>
              </div>
            </div>
            <div className="right">
              <div className="title-bar">
                Annually
                <div className="save">
                  <img src={priceSave} alt="" />
                </div>
                <div className="save-p">
                  save <br /> 15%
                </div>
              </div>
              <div className="col">
                <div className="top-title">EVERYTHING</div>
                <div className="price-title">
                  Platinum Plan <TipIcon size={12} color="#16cc8e" />
                </div>
                <div className="price-value">$1,695</div>
                <div className="bottom-title">/month</div>
                <div className="service">Services</div>
                <ul className="service-ul">
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Graphic Design</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Custom Illustrations</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Logos and Branding Guides</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Presentation Design</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Video Editing</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Motion Graphics</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p className="err">Copywriting</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p className="err">Virtual Assistants</p>
                    <TipIcon size={12} />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p className="err">PSD to Drag and Drop Builders</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p className="err">PSD to HTML</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <div className="feature">features</div>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>{" "}
                    <p>Automated Project Queue</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>{" "}
                    <p>20 Users</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>{" "}
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>30 Brand Buckets </p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Zapier Integration</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Canva Delivery</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Expedited Turnaround</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>All Assist</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Dedicated Project Manager</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Real-Time Collaboration</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Double the Output</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Make Requests via Video & Audio</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <li>
                    <div className="left">
                      <SuccessIcon size={20} color="#16cc8e" />
                    </div>
                    <p>Figma Delivery</p>
                    <TipIcon size={12} color="#e6e3da" />
                  </li>
                  <div
                    className="btn"
                    onClick={() => this.routePage("Platinum")}
                  >
                    Next
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Pricing;
