import React from "react";

const Fackbook = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#3B5997",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="7218"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M0.256 128.256v766.848a128 128 0 0 0 128 128h766.848a128 128 0 0 0 128-128V128.256a128 128 0 0 0-128-128H128.256a128 128 0 0 0-128 128zM674.56 301.824h-59.2c-21.568 0-36.224 4.544-43.776 13.632-7.552 8.96-11.328 22.592-11.328 40.768V427.52h110.528l-14.72 111.68H560.256V825.6H444.8V539.2H348.544V427.52H444.8V345.28c0-46.72 13.056-83.136 39.168-108.8 26.24-25.856 61.056-38.656 104.512-38.656 36.992 0 65.6 1.472 86.016 4.544v99.456h0.128z"
      fill={color}
      p-id="7219"
    ></path>
  </svg>
);

export default Fackbook;
