import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/about.scss";

class About extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="about-page">
          <div className="title">
            About Us
            <div className="bgc"></div>
          </div>
          <p>
            Flocksy is a group of passionate people dedicated to streamlining
            creative work through technology.
          </p>
          <p>
            <b>Our mission</b>
          </p>
          <p>
            We believe that every business owner should want to be a customer of
            their own company — and at Flocksy, we use our own services every
            day. We consider our team to be family, and we’re proud to connect
            our talented creatives with our passionate clients.
          </p>
          <p>
            Our business is based on collaboration, which is why we’re
            constantly collecting feedback, having conversations, and adapting
            our process to better serve modern businesses. We aim to provide
            top-notch yet affordable work to businesses of any size, all managed
            within an intuitive system that fosters relationships between our
            team and our clients.
          </p>
          <p>
            <b>
              The all-in-one solution for your business: <br />
              We're a full service creative agency
            </b>
          </p>
          <p>
            Typically, when businesses outsource creative work, they have to
            find separate teams or freelancers for each type of project. We know
            how much time that can waste, which is why Flocksy offers all major
            creative services for a simple fixed price.
          </p>
          <p>
            Flocksy helps you scale your business without overloading your
            schedule or budget.
          </p>
          <p>
            <b>Flexible custom teams</b>
          </p>
          <p>
            We’ve assembled a broad pool of talented creatives, and our smart
            algorithm identifies the best person for the job.
          </p>
          <p>
            <b>Easy brand development</b>
          </p>
          <p>
            Whether you’re building a new brand or managing several for your own
            clients, you’ll love our versatile project management system.
          </p>
          <p>
            We know that it gets challenging to track and manage multiple
            freelancers, which is why our intuitive platform lets you chat with
            your team, set up various Brand Buckets, and check your project
            timelines.
          </p>
          <p>
            <b>AI Assist makes project brief creation easy!</b>
          </p>
          <p>
            Now, you can effortlessly turn your ideas into detailed creative
            directions by simply typing your thoughts, concepts and ideas into
            our AI Assist feature. The AI will generate comprehensive project
            briefs that you can easily edit or expand upon with your own text.
            This not only speeds up the brief writing process, but also ensures
            that your creative team has a clear understanding of your vision,
            making the process smoother and more efficient.
          </p>
          <p>
            <b>Generate AI Images and add them directly to your brief</b>
          </p>
          <p>
            Our AI image generation tool has you covered. Just type in your
            concepts or ideas, and AI will produce relevant images that you can
            add right into your project brief.{" "}
          </p>
          <p>
            The images can help convey your desired look and feel for any
            marketing materials and provide your creative team with the guidance
            they need to bring your ideas to life.
          </p>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default About;
