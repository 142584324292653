import React from "react";

const Play = ({
  viewBox = "0 0 24 24",
  color = "#ffffff",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="3763"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 21C239.6 21 21.3 236.9 21.3 509s218.2 494 490.6 494c272.4 0 490.7-221.9 490.7-494 0.1-272.1-218.2-488-490.6-488z m-0.6 907.8c-231.8 0-417-189.1-417-420.6 0-231.5 185.2-414.4 417-414.4s417 182.8 417 414.4c-0.1 231.5-185.2 420.6-417 420.6z m-46.1-596c-9.3-7.8-25.5-7.8-34.8 0v359.3c9.3 7.9 25.5 7.9 34.8 0l204.2-165.8c9.3-7.8 9.3-20.5 0-28.4L465.3 332.8z"
      fill={color}
      p-id="3764"
    ></path>
  </svg>
);

export default Play;
