import React from "react";
const TipIcon = ({
  size = 24,
  color = "#ffffff",
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="10229"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 0C230.4 0 0 230.4 0 512s230.4 512 512 512 512-230.4 512-512S793.6 0 512 0z m62.171429 786.285714c-32.914286 36.571429-80.457143 58.514286-128 62.171429-14.628571 0-25.6-3.657143-36.571429-14.628572-10.971429-10.971429-10.971429-25.6-7.314286-40.228571l65.828572-314.514286c-18.285714 14.628571-40.228571 10.971429-54.857143-3.657143-18.285714-14.628571-21.942857-43.885714-7.314286-62.171428 29.257143-36.571429 76.8-58.514286 124.342857-58.514286 14.628571 0 25.6 7.314286 36.571429 18.285714 10.971429 7.314286 10.971429 21.942857 7.314286 36.571429L512 724.114286c14.628571-7.314286 32.914286-7.314286 47.542857 0 10.971429 7.314286 18.285714 14.628571 21.942857 29.257143 3.657143 10.971429 0 21.942857-7.314285 32.914285z m18.285714-486.4c-29.257143 18.285714-69.485714 3.657143-87.771429-25.6-18.285714-29.257143-3.657143-69.485714 25.6-87.771428 29.257143-18.285714 69.485714-3.657143 87.771429 25.6 3.657143 7.314286 7.314286 18.285714 7.314286 25.6 0 29.257143-14.628571 51.2-32.914286 62.171428z"
      p-id="10230"
      fill={color}
    ></path>
  </svg>
);
export default TipIcon;
