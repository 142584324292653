import React from "react";

const YouTube = ({
    viewBox = '0 0 24 24',
    size = 24,
    color= "#d81e06",
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="22763"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M448 640L640 512 448 384v256zM832 0H192A192 192 0 0 0 0 192v640A192 192 0 0 0 192 1024h640A192 192 0 0 0 1024 832V192A192 192 0 0 0 832 0z m-5.461333 626.631111c-3.470222 42.609778-35.783111 96.995556-81.066667 104.903111-144.839111 11.207111-316.757333 9.841778-466.887111 0-46.876444-5.916444-77.653333-62.350222-81.066667-104.903111-7.281778-89.486222-7.281778-140.401778 0-229.888 3.470222-42.552889 34.986667-98.702222 81.066667-103.822222 148.423111-12.458667 321.137778-9.784889 466.887111 0 52.224 1.934222 77.653333 55.637333 81.066667 98.247111a1281.706667 1281.706667 0 0 1 0 235.463111z"
      p-id="22764"
      fill={color}
    ></path>
  </svg>
);


export default YouTube;