import React from "react";

const Instagram = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#d4237a",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="20644"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M176 64h672c61.856 0 112 50.144 112 112v672c0 61.856-50.144 112-112 112H176c-61.856 0-112-50.144-112-112V176c0-61.856 50.144-112 112-112z m579.162 579.112c0 56.434-46.173 102.608-102.607 102.608H396.137c-56.434 0-102.607-46.174-102.607-102.608V396.32c0-56.434 46.173-102.607 102.607-102.607h256.418c56.434 0 102.607 46.175 102.607 102.61v246.79z m-97.883-398.68H391.41c-81.515 0-148.21 66.696-148.21 148.211v254.145c0 81.516 66.695 148.21 148.21 148.21h265.87c81.515 0 148.21-66.694 148.21-148.21V392.643c0-81.515-66.695-148.21-148.21-148.21z m47.923 126.35c0-17.63-14.292-31.922-31.922-31.922-17.63 0-31.922 14.292-31.922 31.922 0 17.63 14.292 31.922 31.922 31.922 17.63 0 31.922-14.292 31.922-31.922zM524.31 610.958c-50.373 0-91.206-40.833-91.206-91.206 0.002-50.376 40.833-91.207 91.209-91.209 50.373 0 91.206 40.833 91.206 91.207 0 50.373-40.833 91.206-91.209 91.208z m0-232.576c-78.077 0-141.368 63.29-141.368 141.368 0 78.077 63.293 141.37 141.37 141.37 78.078 0 141.368-63.29 141.368-141.368 0-78.077-63.292-141.37-141.37-141.37z"
      fill={color}
      p-id="20645"
    ></path>
  </svg>
);

export default Instagram;