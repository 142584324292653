import React, { Component } from "react";
import "../style/home.scss";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import leftGif from "../image/Flocksy_Web_1.gif";
import rightGif from "../image/Flocksy_Web_2.gif";
import img from "../image/flocksysecrets.pngw3.webp";
import Play from "../icon/Play";
import logo1 from "../image/Logo1-Titos.svg";
import logo2 from "../image/Logo2-AmericanAirlines.svg";
import logo3 from "../image/Logo3-Liquid.svg";
import logo4 from "../image/Logo4-Forbes.svg";
import logo5 from "../image/Logo5-Yamaha.svg";
import logo6 from "../image/Logo6-StateFarm.svg";
import logo7 from "../image/Logo7-Adobe.svg";
import logo8 from "../image/Logo8-HarleyDavison.svg";
import panlRabic from "../image/PaulRabic-Benefits-NewImg3.pngw3.webp";
import Michelle from "../image/Michelle-Img1.pngw3.webp";
import Brooke from "../image/Brooke-Img4.pngw3.webp";
import David from "../image/David-Img2.pngw3.webp";
import Paulina from "../image/Paulina-Img3.pngw3.webp";

const intervalTime = 1500; // 定义时间间隔（毫秒）
const blankTime = 1000;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 1,
      showBlank: false,
    };
    this.routePage = this.routePage.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.interval = setInterval(
      () => {
        this.setState((prevState) => ({
          showBlank: !prevState.showBlank,
          index: prevState.index > 6 ? 1 : prevState.index + 1,
        }));
      },
      this.state.showBlank ? blankTime : intervalTime
    );
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      default:
        break;
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let { index, showBlank } = this.state;
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="sec1">
          <div className="left">
            <img src={leftGif} alt="" />
          </div>
          <div className="contain">
            <div className="haif">
              <div className="img-box">
                <img src={img} alt="" />
                <Play size={80} className="icon" />
              </div>
            </div>
            <div className="right-half">
              <h1>Unlimited</h1>
              <h1 className="animate">
                {showBlank ? (
                  <React.Fragment>
                    <div className={`an1 ${index === 1 ? "active" : ""}`}>
                      Graphic Design
                    </div>
                    <div className={`an2 ${index === 2 ? "active" : ""}`}>
                      Video Editing
                    </div>
                    <div className={`an3 ${index === 3 ? "active" : ""}`}>
                      Motion Graphics
                    </div>
                    <div className={`an4 ${index === 4 ? "active" : ""}`}>
                      Illustrations
                    </div>
                    <div className={`an5 ${index === 5 ? "active" : ""}`}>
                      Copywriting
                    </div>
                    <div className={`an6 ${index === 6 ? "active" : ""}`}>
                      Web Development
                    </div>
                    <div className={`an7 ${index === 7 ? "active" : ""}`}>
                      Virtual Assistants
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}
                <div className="line"></div>
                <div className="bgc"></div>
              </h1>
              <p className="litte">
                Your flat rate team of talented professional creatives will
                create amazing on-brand graphics, videos, copy, motion graphics
                and more.
              </p>
              <div className="btn-row">
                <div className="left">
                  <div
                    className="btn"
                    onClick={() => this.routePage("pricing")}
                  >
                    Pricing
                  </div>
                  <p>14-Day Money Back Guarantee</p>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <img src={rightGif} alt="" />
          </div>
        </div>
        <div className="sec2">
          <div className="title">
            Trusted <span>globally</span> since 2016
          </div>
          <div className="img-bar">
            <img src={logo1} alt="" />
            <img src={logo2} alt="" />
            <img src={logo3} alt="" />
            <img src={logo4} alt="" />
            <img src={logo5} alt="" />
            <img src={logo6} alt="" />
            <img src={logo7} alt="" />
            <img src={logo8} alt="" />
          </div>
        </div>
        <div className="sec3">
          <div className="title">
            Our <br /> <span>benefits</span>
            <div className="bgc"></div>
          </div>
          <div className="contain">
            <div className="left">
              <div className="title1">
                A ready-to-go <span>team of creatives</span>
              </div>
              <div className="descrption">
                With each project, you'll be able to build up your team with
                trusted professional video editors, graphic designers,
                illustrators, web developers and more! Over time, your dedicated
                team will get to know your brand and style. From copywriting to
                creating a new landing page, your custom built team will help
                you get it done.
              </div>
              <div className="title2">
                One affordable <span>fixed price</span>
              </div>
              <div className="descrption">
                Everything you need for one flat rate monthly fee — No
                unexpected charges or hidden fees. It really is that simple!
              </div>
              <div className="title3">
                Your own <span>project manager</span>
              </div>
              <div className="descrption">
                Our clients love having someone they can count on to answer
                their questions and give advice when needed. Your project
                manager is there from day one to help you make the most of
                Flocksy's services and deal with any concerns that may pop up.
                We promise no hold music, you'll always receive a response
                within a few hours!
              </div>
              <div className="title4">
                AI <span>Assist</span>
              </div>
              <div className="descrption">
                Writing project briefs is now simpler than ever with our
                innovative platform and cutting edge AI Assist feature! Provide
                a few details, keywords or ideas and AI Assist can help you
                generate creative directions, entire briefs, images and assets
                for your next project.
              </div>
            </div>
            <div className="right">
              <img src={panlRabic} alt="" />
            </div>
          </div>
        </div>
        <div className="sec4">
          <div className="title">
            {" "}
            Our <br /> <span>simple process</span>
            <div className="bgc"></div>
          </div>
          <div className="contain">
            <div className="left">
              <div className="title1">
                Select <span> a plan</span>
              </div>
              <div className="descrption">
                Choose the affordable option that's right for you based on the
                amount of work and the type of projects you need completed
                monthly.
              </div>
              <div className="title2">
                Assemble <span>your team</span>
              </div>
              <div className="descrption">
                You get a fully-vetted, custom-built team of top tier
                professionals from the US and around the world.
              </div>
              <div className="title3">
                Get <span>projects done</span>
              </div>
              <div className="descrption">
                Work with your project manager and creative team to cross tasks
                off your list. Need help getting started? Our AI Assist feature
                can help you write briefs and generate assets.
              </div>
            </div>
            <div className="right">
              <img src={Michelle} alt="" />
            </div>
          </div>
        </div>
        <div className="sec5">
          <div className="row1">
            <div className="l">
              <img src={David} alt="" />
            </div>
            <div className="l">
              <div className="title1">
                Unlimited <br />
                <span>creativity</span>
              </div>
              <p>
                No matter what you throw at us, our <br /> vetted and talented
                creatives are <br /> prepared to tackle it and rapidly <br />{" "}
                deliver high quality work. Let us help <br /> you boost your
                brand and grow your <br />
                exposure with amazing videos,
                <br /> graphics, visuals, web design and
                <br />
                much more
              </p>
            </div>
            <div className="l">
              <img src={Paulina} alt="" />
            </div>
          </div>
          <div className="row1">
            <div className="l">
              <div className="title2">
                Seamless <br />
                <span>communication</span>
              </div>
              <p>
                Whether you manage multiple brands or <br /> are launching a new
                startup (and <br /> anywhere in between) — creating and <br />{" "}
                managing a project is easy! You can start <br /> and manage
                projects from directly in your <br /> Flocksy account, via email
                or using your favorite app (including Slack, Monday, <br />{" "}
                Trello, Discord and over 5000 more) <br />
                though our Zapier integration.
              </p>
            </div>
            <div className="l">
              <img src={Brooke} alt="" />
            </div>
            <div className="l">
              {" "}
              <div className="title3">
                Fast <br />
                <span>turnaround</span>
              </div>
              <p>
                Do you need a lot of projects completed <br /> quickly? No
                problem. We can provide <br /> same day updates on whatever you
                need <br /> done. And with the ability to run multiple <br />{" "}
                project types simultaneously — your <br />
                website and social feeds will be updated <br /> in no time!
              </p>
            </div>
          </div>
        </div>
        {/* <div className="sec6"></div> */}
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Home;
