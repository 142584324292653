import React, { Component } from "react";
import "../../style/common/headerBar.scss";
import Star from "../../icon/Star";
import HalfStar from "../../icon/HalfStar";
import ShoppingCart from "../../icon/ShoppingCart";
import logo from "../../image/logo.png";

class HeaderBar extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="header">
          <div className="logo" onClick={() => this.routePage("home")}>
            <img src={logo} alt="" />
          </div>
          <div className="tab">
            <div className="li" onClick={() => this.routePage("pricing")}>
              Pricing
            </div>

            <div className="li" onClick={() => this.routePage("about")}>
              About Us
            </div>
            <div className="li" onClick={() => this.routePage("contact")}>
              Contact Us
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HeaderBar;
