import React, { Component } from "react";
import "../../style/common/footerBar.scss";
import footImg from "../../image/footer.png";
import logo from "../../image/logo.png";
import Facebook from "../../icon/Facebook";
import Linked from "../../icon/Linked";
import Instagram from "../../icon/Instagram";
import Twtter from "../../icon/Twtter";
import YouTube from "../../icon/YouTube";

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "terms":
        history.push("/Terms");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "about":
        history.push("/About");
        break;
      case "contact":
        history.push("/Contact");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="footer">
          <img src={footImg} alt="" />
        </div>
        <div className="foot">
          <div className="li">
            <div className="logo" onClick={() => this.routePage("home")}>
              <img src={logo} alt="" />
            </div>
            <div className="txt">
              <b>Flocksy</b> has been the most affordable provider of unlimited
              creative services since 2016.
            </div>
          </div>
          <div className="li">
            <div className="title">LEARN MORE</div>
            <div className="tit" onClick={() => this.routePage("about")}>
              About Us
            </div>
            <div className="tit" onClick={() => this.routePage("contact")}>
              Contact Us
            </div>
            <div className="tit" onClick={() => this.routePage("privacy")}>
              Privacy Policy
            </div>
            <div className="tit" onClick={() => this.routePage("terms")}>
              Terms of Service
            </div>
          </div>
          <div className="li">
            <div className="title">CONNECT WITH US</div>
            <div className="icon">
              <Facebook size={25} />
              <Linked size={25} />
              <Instagram size={28} />
              <Twtter size={25} />
              <YouTube size={25} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FooterBar;
