import React from "react";
const Twtter = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#08a2f3",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="21677"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M881.107-18.243h-738.391c-88.844 0-161.076 71.997-161.076 160.781v738.511c0 89.082 72.23 161.194 161.076 161.194h738.335c89.021 0 161.252-72.113 161.252-161.134v-738.57c0.059-88.786-72.23-160.781-161.194-160.781zM821.603 389.75c0.177 6.009 0.177 11.959 0.177 18.087 0 183.522-139.515 395.209-395.149 395.209-78.417 0-151.533-22.86-213.041-62.51 11.018 1.473 21.917 2.003 33.346 2.003 64.985 0 125.078-22.27 172.389-59.327-60.801-1.297-111.998-41.419-129.968-96.505 8.837 1.414 17.204 2.474 26.218 2.474 12.609 0 25.038-1.945 36.704-5.067-63.335-12.784-111.468-68.755-111.468-136.212v-1.826c18.558 10.486 40.299 16.909 62.922 17.38-37.295-24.745-61.862-67.341-61.862-115.653 0-25.393 7.13-49.313 18.853-69.637 68.401 84.133 170.916 139.216 286.272 145.11-2.298-10.134-3.535-20.857-3.535-31.755 0-76.531 62.332-138.864 138.806-138.864 39.946 0 76.061 16.909 101.454 43.95 31.696-6.246 61.451-17.969 88.434-33.759-10.605 32.522-32.462 59.625-61.331 76.825 28.162-3.358 54.791-10.839 79.889-21.858-18.442 27.867-42.125 52.317-69.108 71.937z"
      fill={color}
      p-id="21678"
    ></path>
  </svg>
);

export default Twtter;
